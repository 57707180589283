<template>
  <div class="FixedCenterContainer" v-if="!hide">
    <div class="FlexCenterContainer">
      <div class="ScrollToPlanButton">Получить план питания <span class="Flare"/></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlanButton",
  props: {
    hide: {
      type: Boolean, default: false
    }
  }
}
</script>

<style>
.FixedCenterContainer {
  position: fixed;
  overflow: hidden;
  bottom: 16px;
  width: 420px;
  transition: all 0.3s linear;
}
.ScrollToPlanButton {
  display: flex;
  cursor: pointer;
  background: var(--color-primary50);
  color: white;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  border-radius: 8px;
  min-width: 90%;
  /*border: 3px solid var(--color-primary70);*/
}

.Flare {
  position: absolute;
  top: 0;
  height: 100%;
  width: 45px;
  transform: skewX(-27deg);
  left: -150%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.38), rgba(255, 255, 255, 0.62));
  animation: flareAnimation 5s infinite linear;
}

@keyframes flareAnimation {
  0% {
    left: -350%;
  }
  100% {
    left: 350%;
  }
}

@media(max-width: 420px) {
  .FixedCenterContainer {
    width: 100%;
    max-width: 100%;
  }
  .ScrollToPlanButton {
    width: 95%;
    margin-left: 2px;
    /*border: 3px solid var(--color-primary70);*/
  }
}
</style>
