<template>
  <div class="PdBlock Gapped Rounded">
    <div class="MicroHeader">Мы уже создали</div>
    <div class="Header"><span class="HeaderAccent">12,6</span> тысяч планов питания</div>
    <div class="MicroHeader">для наших пользователей, чтобы помочь им достичь своих целей по снижению веса</div>
    <div class="Dishes">
      <div class="Dish" v-for="dish in dishes" :key="dish.src">
        <rota-image :src="dish.src" :reversed="dish.reversed"/>
      </div>
    </div>
    <div class="ProofsBlock">
      <proof-item v-for="(item, index) in proofs" :key="index" :item="item"/>
    </div>
    <div class="BeforeAfterBlock">
      <img src="/assets/img/proofs/before-after.png" class="BeforeAfter"/>
      <div class="BeforeAfterTitle">Даша, 23 года</div>
      <div class="BeforeAfterText">Ваши личные результаты могут отличаться от результатов Даши</div>
      <div class="HonestBlock">
        Самый удобный и <b>эффективный помощник</b> по снижению веса.
      </div>
    </div>
  </div>

</template>

<script>
import RotaImage from "@/views/8_StepPlanPreview/widgets/RotaImage.vue";
import {PROOFS, ROTATED_DISHES} from "@/entities/consts";
import ProofItem from "@/views/8_StepPlanPreview/components/Proofs/ProofItem.vue";

export default {
  name: "PlanResultsBlock",
  components: {ProofItem, RotaImage},
  data() {
    return {
      dishes: ROTATED_DISHES,
      proofs: PROOFS,
    }
  }
}
</script>

<style scoped>
.Dishes {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 16px auto 28px;
  max-width: 328px;
}

.BeforeAfterBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.BeforeAfterTitle {
  font-weight: bold;
  font-size: 16px;
}
.BeforeAfterText {
  font-weight: normal;
  font-size: 14px;
  text-align: center;
}
.HonestBlock {
  display: block;
  position: relative;
  text-align: center;
  max-width: 220px;
  min-height: 120px;
  margin: 64px 0 0;
}
.HonestBlock:before,
.HonestBlock:after {
  content: "";
  background: url('../../../../public/assets/img/proofs/lavr.svg') 0 0 no-repeat;
  position: absolute;
  top: 0;
  transform: translateX(-80px);
  display: inline-block;
  width: 40px;
  height: 125px;
}
.HonestBlock:after {
  transform: scaleX(-1) translateX(-40px);
}

.ProofsBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
