<template>
  <div :class="itemClasses" @click="$emit('clickItem')">
    <img v-if="image" :src="image" class="SurveyItemImage" />
    {{ label }}
  </div>
</template>

<script>
export default {
  name: "SurveyItem",
  props: {
    id: { type: [String, Number], default: '' },
    label: { type: String, default: '' },
    image: { type: String, default: '' },
    isActive: { type: Boolean, default: false },
    onItemClick: { type: Function, default: () => {} },
  },
  computed: {
    itemClasses() {
      const resultClasses = ["SurveyItem", "FlexColumn"];

      if (this.isActive) resultClasses.push("active");

      return resultClasses;
    }
  },

}
</script>

<style scoped>
.SurveyItem {
  gap: 8px;
  border: 1px solid #e0e0e0;
  box-shadow: 1px 1px 6px 0px rgba(0,0,0,0.1);
  padding: 16px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.SurveyItem.active {
  box-shadow: 1px 1px 6px 0px rgba(0,0,0,0.1) inset;
  background-color: #fafafa;
}
.SurveyItemImage {
  width: auto;
  max-height: 100px;
}
</style>
