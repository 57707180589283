<template>
  <page-view title="Тесты">
    <button @click="sendPlanData">Send plan data</button>
  </page-view>
</template>

<script>
import PageView from "@/components/Layouts/PageView/PageView.vue";
import {useApi, usePlanData} from "@/entities/hooks";

const api = useApi();

export default {
  name: "TestPage",
  components: {PageView},
  methods: {
    async sendPlanData() {
      const planData = usePlanData();
      const result = await api.makePlan(planData);
      console.log(result);
    }
  }
}
</script>

<style scoped>

</style>
