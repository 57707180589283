<template>
  <div class="LayoutLevel1">
    <main role="main" class="bg-white rounded shadow PageViewContainer">
      <router-view/>
    </main>
  </div>
</template>

<script>

import {useQuizStore} from "@/app/stores/QuizStore";

export default {
  name: "PageLayout",
  computed: {
    theState() {
      const store = useQuizStore();

      return store.$state;
    }
  }
}
</script>

<style>
.LayoutLevel1 {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.PageViewContainer {
  height: 100%;
  width: 420px;
  overflow: auto;
  position: relative;
}

.BasicButton {
  display: flex;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background: var(--color-primary50);
  color: white;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  border-radius: 8px;
  max-width: 100%;
  min-width: 100%;
}

.TextButton {
  display: flex;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background: white;
  color: var(--color-black60);
  font-size: 18px;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  border-radius: 8px;
  max-width: 100%;
  min-width: 100%;
}

@media (max-width: 420px) {
  .PageViewContainer {
    margin-top: 0;
    height: 100vh;
  }
}

@media (max-width: 380px) {
  .PageViewContainer {
    margin-top: 0;
    height: 100vh;
    width: 100vw;
  }
}
</style>
