<template>
  <div class='Slogan pt-5 pb-4 sm:pt-4 sm:pb-4'>
    <h2 class='SloganTitle text-4xl text-center' style="margin-bottom: 12px">{{ title }}</h2>
    <h3 class='SloganSubtitle text-center text-silver text-md px-4'>{{ subtitle }}</h3>
  </div>
</template>

<script>
export default {
  name: "GenderSlogan",
  props: {
    title: {
      type: String, default: ''
    },
    subtitle: {
      type: String, default: ''
    },
  }
}
</script>

<style scoped>

</style>
