<template>
  <div class="PdBlock Gapped">
    <h2 class="ForecastHeader">Прогноз результатов
      <p class="ForecastSubHeader">на основе Ваших ответов</p>
    </h2>
    <block-view title="Прогноз изменения веса" :value="`${plan.forecast} кг`">
      <forecast-graph :from="plan.weight" :to="plan.target_weight"/>
    </block-view>

    <block-view title="После первой недели" :value="`${plan.forecast_first_week} кг`" :image="SVG_FIRE"/>
    <block-view title="Минимальная норма воды в сутки" :value="`${plan.water_per_day} л`" :image="SVG_WATER"/>
    <block-view title="Норма калорий в сутки" :value="`${plan.calories} ккал`" :image="SVG_KCAL"/>
    <block-view title="Первый видимый результат" :value="`${plan.first_result} дней`" :image="SVG_RESULT"/>
  </div>
</template>

<script>
import BlockView from "@/views/8_StepPlanPreview/components/View/BlockView.vue";
import ForecastGraph from "@/views/8_StepPlanPreview/components/View/ForecastGraph.vue";

export default {
  name: "PlanForecastBlock",
  components: {ForecastGraph, BlockView},
  props: {plan: {type: Object, default: () => ({})}},
  data() {
    return {
      SVG_FIRE: '/assets/img/ext/After_first_week.svg',
      SVG_WATER: '/assets/img/ext/Water.svg',
      SVG_KCAL: '/assets/img/ext/Calories.svg',
      SVG_RESULT: '/assets/img/ext/Duration.svg',
      SVG_AGE: '/assets/img/ext/Age.svg',
      SVG_HEIGHT: '/assets/img/ext/Height.svg',
    }
  },
}
</script>

<style scoped>

</style>
