<template>
  <div class="StepFoods PageStep">
    <p>Select foods</p>
  </div>
</template>
<script>
export default {
  name: 'StepFoods'
}
</script>
