<template>
  <div class="PdBlock Gapped" ref="planlist">
    <h2 class="ChoosePlanHeader">Выберите Ваш план</h2>
    <div class="ChoosePlan">
      <plan-item v-for="(item, index) in plans" :key="index" v-bind="item" :is-active="activeItem == item"
                 @click="select(item)"/>
      <div class="ProofLine">
        По статистике 3-месячный план позволяет похудеть в 2 раза сильнее, чем 1-месячный
      </div>
      <div class="FlexCenterContainer">
        <div class="GetPlanButton" @click="upsell">Продолжить<span class="Flare"/></div>
      </div>
      <div class="PlanConditions">Продолжая, вы соглашаетесь с Условиями предоставления услуг, Политикой
        конфиденциальности, политикой возврата средств, условиями подписки, политикой использования файлов cookie.
      </div>
    </div>

  </div>

</template>

<script>
import PlanItem from "@/views/8_StepPlanPreview/components/Plan/PlanItem.vue";
import {useQuizStore} from "@/app/stores/QuizStore";

// product - versions

const PLANS = [
  // {id: 'week1', name: '1 неделя', currency: '₽', price_old: 630, price: 210, price_per_day: 30, message: ''},
  {id: 'week2', name: '2 недели', currency: '₽', price_old: 700, price: 280, price_per_day: 20, message: ''},
  {id: 'month1', name: '1 месяц', currency: '₽', price_old: 1500, price: 450, price_per_day: 15, message: ''},
  {id: 'month3', name: '3 месяца', currency: '₽', price_old: 4500, price: 900, price_per_day: 10, message: 'самый популярный'},
];
export default {
  name: "ChoosePlanBlock",
  components: {PlanItem},
  data() {
    return {
      ref1: 'target_button',
      plans: PLANS,
      activeItem: PLANS[1],
    }
  },
  async beforeMount() {
    // const actualPlan = await fetch('')
  },
  mounted() {
    this.$emit('element', this.$refs.planlist);
  },
  methods: {
    select(item) {
      this.activeItem = item;

    },
    upsell() {
      const item = this.activeItem;
      const store = useQuizStore();
      const cartItem = {
        id: item.id,
        name: `Персональный план питания на ${item.name}`,
        old_price: item.price_old,
        new_price: item.price,
      }
      store.addToCart(cartItem);
      this.$router.push('/upsell');
    }
  }
}
</script>

<style scoped>
.ChoosePlanHeader {
  margin: 0;
  flex-direction: column;
  font-size: 24px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ChoosePlan {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  background: rgb(250, 242, 232);
  padding: 36px 16px 40px;
  margin-bottom: 16px;
  border-radius: 8px;
}

.ProofLine {
  color: var(--color-black60);
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.ProofLine:before {
  content: "*";
  color: red;
}

.GetPlanButton {
  display: flex;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background: var(--color-primary50);
  color: white;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  border-radius: 8px;
  max-width: 100%;
  min-width: 100%;
}

.PlanConditions {
  font-size: 12px;
  color: var(--color-black60);
}
</style>
