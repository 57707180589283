<template>
  <div class="BodyIndexFade" v-if="show" @click="$emit('close')">
    <div class="BodyIndexModal">
      <div class="BodyIndexModalCloser">&times;</div>
      <div class="BodyIndexModalTitle">ИМТ</div>
      <div class="BodyIndexModalText"><b>Индекс массы тела (ИМТ)</b> это величина, полученная из соотношения роста и веса человека.</div>
      <div class="BodyIndexModalText">Применяется для классификации тела человека с недостаточным весом, нормальным, избыточным или ожирением.</div>

      <div class="BmiItem" v-for="item in items" :key="item[0]"  :style="{background: item[3] + '44'}">
        <div class="BmiItemText">{{ item[2] }}</div>
        <div class="BmiItemValue" :style="{background: item[4]}"><b>{{ formatItemValue(item) }}</b></div>
      </div>
    </div>
  </div>
</template>

<script>
import {BMI} from "@/entities/consts";

export default {
  name: "BodyIndexModal",
  props: {
    show: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      items: BMI,
    }
  },
  methods: {
    formatItemValue(item) {
      if (item[0] === 0) {
        return "< " + (item[1] + 0.1);
      }
      if (item[1] > 100) {
        return "> " + (item[0]);
      }
      return item[0] + ' - ' + item[1];
    }
  }
}
</script>

<style scoped>
.BodyIndexFade {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  padding: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0.5, 0.5, 0.5, 0.8);
}

.BodyIndexModal {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 16px 16px;
  background: white;
  border-radius: 8px;
  max-width: 400px;
}
.BodyIndexModalCloser {
  position: absolute;
  right: 16px;
  top: 0px;
  font-size: 32px;
  cursor: pointer;
}
.BodyIndexModalTitle {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 16px;
}
.BodyIndexModalText {
  font-size: 14px;
  margin-bottom: 16px;
}
.BmiItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 8px 12px;
  background: #e0e0e0;
  border-radius: 6px;
}
.BmiItemValue {
  padding: 4px;
  border-radius: 6px;
  background: #fafafa;
  color: white;
  font-weight: 500;
  min-width: 35%;
  width: 35%;
  text-align: center;
}
.BmiItemText {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  width: 65%;
  max-width: 65%;
  border-radius: 6px;
}
.BmiItemText:after {
  position: absolute;
  right: -10px;
  bottom: 0px;
  width: 0px;
  height: 0px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
</style>
