<template>
  <img :src="src" class="RotaImage" :class="{'Reversed': reversed }"/>
</template>

<script>
export default {
  name: "RotaImage",
  props: {
    src: String,
    reversed: { type: Boolean, default: false },
  }
}
</script>

<style scoped>
@keyframes rotator {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(359deg);
  }
}
  .RotaImage {
    animation: 5s linear 0s infinite normal none running rotator;
    transition: all 3s linear;
  }
  .RotaImage.Reversed {
    animation-direction: reverse;
  }
</style>
