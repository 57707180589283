<template>
  <div class="CommonButton">
    {{ label }}
  </div>
</template>

<script setup>
import {defineProps} from 'vue';

defineProps(['label']);
</script>

<script>
export default {
  name: "CommonButton"
}
</script>

<style>
.CommonButton {
  display: block;
  text-align: center;
  width: 100%;
  padding: 16px 24px;
  background-color: var(--color-primary50);
  color: white;
  border-radius: 8px;
}
</style>
