<template>
  <div class="PdBlock Gapped">
    <h2 class="ForecastHeader">Ваш краткий профиль</h2>
    <body-index :index="plan.body_index"/>
    <block-view title="Возраст" :value="`${plan.age} лет`" :image="SVG_AGE"/>
    <block-view title="Рост / Вес" :value="`${plan.height} см / ${plan.weight} кг`" :image="SVG_HEIGHT"/>
  </div>
</template>

<script>
import BodyIndex from "@/views/8_StepPlanPreview/components/Body/BodyIndex.vue";
import BlockView from "@/views/8_StepPlanPreview/components/View/BlockView.vue";

export default {
  name: "PlanProfileBlock",
  components: {BlockView, BodyIndex},
  props: {plan: {type: Object, default: () => ({})}},
}
</script>

<style scoped>

</style>
