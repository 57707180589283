export const FooterItemState = {
    Empty: '', Active: 'active', Completed: 'completed',
};

export const FooterItemStateNames = Object.keys(FooterItemState);

export const QuizStep = {
    SelectGender: "gender-select",
    Target: "target",
    Measures: "measures",
    BodyType: "body-type",
    Activity: "activity",
    FoodSelect: "food-select",
    Calculation: "calculation",
    PlanPreview: "plan-preview",
    Upsell: "upsell",
    Payment: "payment",
    Completed: "completed",
}
export const QuizStepNames = Object.keys(QuizStep);
export const QuizStepValues = Object.values(QuizStep);
export const Empty = '';
export const Gender = {
    Male: 'male',
    Female: 'female',
}
export const WeightTarget = {
    Loose: "loose",
    Keep: "keep",
    Gain: "gain",
}
export const BodyType = {
    Ectomorph: 0,
    Mesomorph: 1,
    Endomorph: 2,
}

export const BodyTypeNames = Object.keys(BodyType);

export const BodyMeasures = () => ({
    age: 0,
    weight: 0,
    height: 0,
    target_weight: 0,
});

export const RootState = {
    planId: Empty,
    plan: null,
    activity: Empty,
    step: Empty,
    gender: Empty,
    measures: BodyMeasures(),
    target: Empty,
    bodyType: Empty,
    foods: {},
    cart: [],
}

export const SurveyType = {
    text: 0, textImage: 1, image: 2
}
