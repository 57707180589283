<template>
  <div class="StepPlanPreview PageStep">
    <div class="StepPlanGuide">
      Листайте вниз до конца
      <div class="IconContainer">
        <div class="IconArrow"></div>
      </div>
    </div>
    <plan-forecast-block :plan="plan"/>
    <plan-profile-block :plan="plan"/>
    <plan-results-block/>
    <choose-plan-block @element="onPlanListMounted"/>
    <money-back-block/>
    <faq-block/>
    <copyrights-block/>
    <plan-button @click="onGetPlan" :hide="isGetPlanVisible"/>
  </div>
</template>

<script>
import {defineComponent, ref} from "vue";
import PlanForecastBlock from "@/views/8_StepPlanPreview/widgets/PlanForecastBlock.vue";
import PlanProfileBlock from "@/views/8_StepPlanPreview/widgets/PlanProfileBlock.vue";
import PlanResultsBlock from "@/views/8_StepPlanPreview/widgets/PlanResultsBlock.vue";
import PlanButton from "@/views/8_StepPlanPreview/components/Plan/PlanButton.vue";
import ChoosePlanBlock from "@/views/8_StepPlanPreview/widgets/ChoosePlanBlock.vue";
import MoneyBackBlock from "@/views/8_StepPlanPreview/widgets/MoneyBackBlock.vue";
import FaqBlock from "@/views/8_StepPlanPreview/widgets/FaqBlock.vue";
import CopyrightsBlock from "@/views/8_StepPlanPreview/widgets/CopyrightsBlock.vue";
import {useQuizStore} from "@/app/stores/QuizStore";
import {QuizStep} from "@/entities/types";

export default defineComponent({
  components: {
    CopyrightsBlock,
    FaqBlock,
    MoneyBackBlock, ChoosePlanBlock, PlanButton, PlanResultsBlock, PlanProfileBlock, PlanForecastBlock
  },
  data() {
    return {
      scrollElement: null,
      observer: null,
      isGetPlanVisible: false,
    }
  },
  mounted() {
    const QuizStore = useQuizStore();

    QuizStore.updateValues({step: QuizStep.PlanPreview});
  },
  setup() {
    const QuizStore = useQuizStore();

    const plan = ref(QuizStore.plan);

    return {
      plan
    }
  },
  methods: {
    onObserve([entry]) {
      this.isGetPlanVisible = entry.isIntersecting;
    },
    onGetPlan() {
      if (this.scrollElement) {
        this.scrollElement.scrollIntoView({behavior: "smooth"});
      }
    },
    onPlanListMounted(el) {
      this.scrollElement = el;
      this.observer = new window.IntersectionObserver(this.onObserve, {
        root: null,
        threshold: 0.5, // set offset 0.1 means trigger if atleast 10% of element in viewport
      })
      this.observer.observe(el);
    }
  },
});

</script>

<style>
.StepPlanPreview {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow-y: auto;
}

.PdBlock {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 16px;
  margin-bottom: 16px;
}

.PdBlock.Gapped {
  gap: 16px;
}

.PdBlock.Rounded {
  background: #f5f5f5;
  padding: 32px;
  border-radius: 32px;
}

.ForecastHeader {
  margin: 0;
  flex-direction: column;
  font-size: 32px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ForecastSubHeader {
  text-align: center;
  font-size: 18px;
  font-weight: 300;
}

.MicroHeader {
  text-align: center;
}

.Header {
  font-size: 24px;
  font-weight: 600;
}

.HeaderAccent {
  background: url("decor.svg") no-repeat 0 0;
  padding: 4px;
  display: inline-block;
  background-size: cover;
}

.StepPlanGuide {
  font-size: 24px;
  text-align: center;
  color: var(--color-black30);
  margin-bottom: 30px;
}

:root {
  --arrow-color: #62c9c1;
}

.IconContainer {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) rotate(90deg) scale(75%);
  width: 80px;
  height: 60px;
  cursor: pointer;
  margin: 0;
}

.IconArrow {
  position: absolute;
  top: 25px;
  width: 90%;
  height: 10px;
  background-color: var(--arrow-color);
  box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
  animation: IconArrow 700ms linear infinite;
}

@keyframes IconArrow {
  0% {
    left: 0;
  }
  50% {
    left: 10px;
  }
  100% {
    left: 0;
  }
}

.IconArrow::after, .IconArrow::before {
  content: '';
  position: absolute;
  width: 60%;
  height: 10px;
  right: -8px;
  background-color: var(--arrow-color);
}

.IconArrow::after {
  top: -12px;
  transform: rotate(45deg);
}

.IconArrow::before {
  top: 12px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
  transform: rotate(-45deg);
}
</style>
