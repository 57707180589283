<template>
  <div className="StepCalculation PageStep">
    <page-view
        :show-back="false"
        title="Расчет плана питания"
        subtitle="Прогноз результатов программы"
    >
      <calculator-output @on-complete="nextPage" :force-completed="is_loaded"/>
      <!--      <food-loader @on-complete="nextPage" />-->
    </page-view>
  </div>
</template>

<script setup>
import PageView from "@/components/Layouts/PageView/PageView.vue";
import CalculatorOutput from "@/components/Animations/Calculator.vue";
import {useRouter} from "vue-router";
import {onMounted, ref} from "vue";
import {useQuizStore} from "@/app/stores/QuizStore";
import {QuizStep} from "@/entities/types";
import {useApi, usePlanData} from "@/entities/hooks";
// import FoodLoader from "@/views/7_StepCalculation/components/FoodLoader.vue";

const router = useRouter();
const QuizStore = useQuizStore();

QuizStore.updateValues({step: QuizStep.Calculation});

const planData = usePlanData();

function nextPage() {
  setTimeout(() => router.push('/plan-preview'), 2000);
}

const api = useApi();

const is_loaded = ref(false);

onMounted(async () => {

  const result = await api.makePlan(planData);
  if (result) {
    QuizStore.updateValues({plan: result})
    is_loaded.value = true;
  }
});
</script>
