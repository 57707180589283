<template>
  <div class="ForecastGraph">
    <div class="ForecastGraphRow" v-for="row in rows" :key="row">
      <div class="GraphY">{{ row }}</div>
    </div>
    <img :src="imageSrc" class="ForecastGraphImage"/>
  </div>
</template>

<script>
export default {
  name: "ForecastGraph",
  props: {
    from: {type: [Number, String], default: 0},
    to: {type: [Number, String], default: 0},
  },
  data() {
    return {
      imageSrcDown: '/assets/img/ext/graph_down.svg',
      imageSrcUp: '/assets/img/ext/graph_up.svg',
    }
  },
  computed: {
    imageSrc() {
      return (this.from > this.to) ? this.imageSrcDown : this.imageSrcUp;
    },
    rows() {
      const min = Math.min(this.from, this.to);
      const max = Math.max(this.from, this.to);
      const rows = [max];

      const step = (max - min) / 3;
      let value = max;

      for (let i = 0; i < 2; i++) {
        value = Math.round(max - step);
        rows.push(value);
      }

      rows.push(min);

      return rows;
    }
  }
}
</script>

<style scoped>
.ForecastGraph {
  position: relative;
  width: 100%;
}
.ForecastGraphRow {
  width: 100%;
}

.GraphY {
  display: block;
  padding: 20px 0;
  font-size: 16px;
  line-height: 1;
  color: gray;
}

.GraphY:before {
  display: block;
  content: " ";
  margin-left: 48px;
  transform: translateY(8px);
  border-bottom: 1px solid #eeeeee;
}

.ForecastGraphImage {
  position: absolute;
  top: 24px;
  left: 80px;
}
</style>
