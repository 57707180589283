// import {useQuizStore} from "@/app/stores/QuizStore";
// import {useRouter} from "vue-router";

// export const useStep = (next, timeout = 500) => {
//     return async (valueObject) => {
//         const router = await useRouter();
//
// (useQuizStore()).updateValues(valueObject);
//
// setTimeout(() => router.push(next), timeout);
// }
// }
// import * as browserUserData from 'browser-user-data';
import {useQuizStore} from "@/app/stores/QuizStore";
import {FOOTER_STEPS} from "@/entities/consts";

export const useStepActive = (currentStep) => {
    const store = useQuizStore();

    return store.$state.step === currentStep;
}

export const useStepCompleted = (currentStep) => {
    const store = useQuizStore();
    const index = FOOTER_STEPS.indexOf(store.$state.step);
    const indexCurrent = FOOTER_STEPS.indexOf(currentStep);

    return index > indexCurrent;
}

export const useSessionStorage = (key, data = null) => {
    if (!key) return;

    if (data) {
        sessionStorage.setItem(key, JSON.stringify(data));
    }
    const item = sessionStorage.getItem(key);

    if (item) {
        return JSON.parse(item);
    }
}

export const usePlanData = () => {
    const store = useQuizStore();
    const data = {...store.$state};

    delete data.step;
    delete data.foods;
    delete data.plan;

    return data;
}

const API_HOST = 'https://menumaker.ru'
export const useApi = () => {
    const hostname = window.location.hostname === 'localhost' ? '' : API_HOST;
    return {
        host: `${hostname}/api/`,
        makePlan: async function (planData) {

            // const userData = await browserUserData.extractData(
            //     {
            //         extractLocation: false
            //     }
            // );

            // const response = await fetch(`${this.host}quiz.php`,{ planData, userData });
            const params = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(planData)
            };

            const response = await fetch(`${this.host}quiz.php`, params);
            const result = await response.json();

            return result;
        }
    }
}

export const useRequest = (path = '') => {
    return {
        // host: 'https://menumaker.ru/api/',
        host: path,
        makePlan: async function (planData) {

            // const userData = await browserUserData.extractData(
            //     {
            //         extractLocation: false
            //     }
            // );

            // const response = await fetch(`${this.host}quiz.php`,{ planData, userData });
            const params = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(planData)
            };

            const response = await fetch(`${this.host}quiz.php`, params);
            const result = await response.json();

            return result;
        }
    }
}
