<template>
  <div class="BlockView">
    <div class="BlockViewData">
      <img v-if="image" class="BlockViewIcon" :src="image"/>
      <div class="BlockViewTexts">
        <div v-if="title" class="BlockViewTitle">{{ title }}</div>
        <div v-if="value" class="BlockViewValue">{{ value }}</div>
      </div>
    </div>
    <div class="BlockViewSlot">
      <slot/>
    </div>
  </div>
</template>

<script>

export default {
  name: "BlockView",
  props: {
    title: String,
    value: String,
    image: String,
  }
}
</script>

<style scoped>
.BlockView {
  border-radius: 8px;
  border: 2px solid #e9e9e9;
  padding: 16px;
}
.BlockViewData {
  display: flex;
  gap: 16px;
}

.BlockViewTexts {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  line-height: 1.4;
}

.BlockViewIcon {
  display: block;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  width: 0;
  height: 0;
  min-width: 56px;
  max-width: 56px;
  min-height: 56px;
  max-height: 56px;
}

.BlockViewTitle {
  font-size: 16px;
}

.BlockViewValue {
  /*text-align: right;*/
  font-size: 22px;
  font-weight: 600;
}

.BlockViewTitle.withIcon,
.BlockViewValue.withIcon {
  margin-left: 64px;
}
</style>
