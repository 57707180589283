<template>
  <div class="StepCompleted PageStep">
    <page-view
        title="Скачивание плана"
        subtitle="Поздравляем! Ваш план доступен для скачивания"
    >
      here
    </page-view>
  </div>
</template>
<script setup>

</script>
