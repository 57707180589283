<template>
  <div class="MoneyBack">
    <h3 class="MoneyBackTitle">Гарантия возврата</h3>
    <p>Скорее всего, вы уже пытались достичь своей цели и разочаровались, не увидев никаких результатов. Мы верим, что
      потеря веса может быть здоровой, легкой и устойчивой. Скажите "нет" отказу от ваших любимых блюд. Мы настолько
      уверены в нашей программе, что гарантируем полный возврат средств в течение 30 дней после покупки, если вы не
      получите видимых результатов и сможете доказать, что следовали своему плану. <a class="TextLink" href="/refund-policy">Подробнее</a></p>
  </div>
</template>

<script>
export default {
  name: "MoneyBackBlock"
}
</script>

<style scoped>
.MoneyBack {
  display: flex;
  flex-direction: column;
  background: var(--color-primary0);
  padding: 30px 24px 64px;
}
.MoneyBackTitle {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 12px;
  text-align: center;
}
</style>
