<template>
  <div className="StepGender PageStep">
    <GenderSlogan v-bind="SLOGAN" />
    <GenderSelector/>
    <GenderFooter/>
  </div>

</template>

<script>
import {MALE_BTN, FEMALE_BTN, SLOGAN} from "@/entities/consts";
import {Empty, Gender} from "@/entities/types";
import GenderFooter from "@/views/1_StepGender/components/GenderFooter.vue";
import GenderSlogan from "@/views/1_StepGender/components/GenderSlogan.vue";
import GenderSelector from "@/views/1_StepGender/components/GenderSelector.vue";

export default {
  name: "StepGender",
  components: {GenderSelector, GenderSlogan, GenderFooter},
  data() {
    return {
      MALE_BTN,
      FEMALE_BTN,
      SLOGAN,
      Gender,
      gender: Empty,
    }
  },
}
</script>
<style>
.StepGender {
}

.GenderSelector {
  position: relative;
  width: 100%;
  min-height: 400px;
}

.GenderBgImage {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 0;
  background: url("pair.png") no-repeat 50% 0%;
  background-size: contain;
}

.GenderButtons {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 200px;
  z-index: 9;
}

.GenderMale {
  animation: male-1 1.2s infinite;
}

.GenderFemale {
  animation: female-1 1.67s infinite;
}

.GenderArrows {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 100px;
  left: 10px;
  right: 10px;
}

.GenderArrow {
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
}

.GenderArrow.Left {
  background: url(./red-arrow.png) 50% 50% no-repeat;
  background-size: contain;
}

.GenderArrow.Right {
  background: url(./red-arrow-reverse.png) 50% 50% no-repeat;
  background-size: contain;
}

@keyframes male-1 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(65, 213, 228, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(65, 213, 228, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(65, 213, 228, 0);
  }
}

@keyframes female-1 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(239, 145, 218, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(239, 145, 218, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(239, 145, 218, 0);
  }
}
</style>
