<template>
  <div class="UpsellList">
    <article class="UpsellItem" v-for="item in items"
             :key="item.id"
             v-show="item.id == currentId">
      <h3 class="UpsellItemTitle">{{ item.title }}</h3>
      <img :src="item.img" v-if="item.img">
      <p>{{ item.text }}</p>
      <div class="UpsellPrices">
        <span class="PriceOld">{{ getPrice(item.old_price) }} ₽</span>
        <span class="PriceNew">{{ getPrice(item.new_price) }} ₽</span>
      </div>
      <div class="UpsellButtons">
        <div class="BasicButton" @click="addItem(item)">Добавить в заказ</div>
        <div class="TextButton" @click="skip">В другой раз</div>
      </div>
    </article>
  </div>
</template>

<script>
import {useQuizStore} from "@/app/stores/QuizStore";

const UPSELL_ITEMS = [
  {
    id: 1,
    title: 'Второй план за полцены!',
    text: 'Купите второй план для своего друга, подруги, близкого человека за полцены и достигайте вместе поставленных целей!',
    img: '/assets/img/upsell/upsell-1.png',
    old_price: '%100',
    new_price: '%50',
    action: ''
  },
  {
    id: 2,
    title: 'Сладкий сборник - Десерты ПП',
    text: 'Украсьте свое меню вкуснейшими десертами из натуральных продуктов! Сборник включает более 100 рецептов для домашнего приготовления.',
    img: '/assets/img/upsell/upsell-2.png',
    old_price: 800,
    new_price: 350,
    action: ''
  },
];

export default {
  name: "UpsellList",
  data() {
    return {
      currentId: UPSELL_ITEMS[0].id,
      items: UPSELL_ITEMS,
    }
  },
  methods: {
    getPrice(value) {
      let price = value;

      const store = useQuizStore();
      if (String(value).indexOf('%') == 0) {
        price = Number(String(value).substring(1));
        if (store.tariff) {
          price = price / 100 * store.tariff.price;
        }
        return Math.round(price);
      }
      return price;
    },
    addItem(item) {
      const store = useQuizStore();
      const cartItem = {
        id: item.id,
        name: item.title,
        old_price: this.getPrice(item.old_price),
        new_price: this.getPrice(item.new_price),
      }
      store.addToCart(cartItem);
      this.skip();
    },
    skip() {
      const nextId = this.currentId + 1;
      const nextItem = this.items.filter(el => el.id === nextId).pop();

      if (nextItem) {
        this.currentId = nextItem.id;
      } else {
        this.$router.push('/payment')
      }
    }
  }
}
</script>

<style scoped>
.UpsellItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
}

.UpsellItemTitle {
  font-size: 24px;
  text-align: center;
}

.UpsellButtons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.UpsellPrices {
  display: flex;
  gap: 32px;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  padding: 24px;
}
.PriceOld {
  color: var(--color-black60);
  text-decoration: line-through;
}
</style>
