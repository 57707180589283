<!-- eslint-disable vue/valid-define-props -->
<template>
<div>
    <div class="RangeInputValues">
        <label :for="inputName" class="RangeLabel">{{ label }}</label>
        <div class="RangeValue">{{ value }} {{ units }}</div>
    </div>
    <input class="RangeInput" type="range" :name="inputName" v-model="value" :placeholder="label" :min="min" :max="max" :step="step" />
</div>
</template>

<script setup>

import {
  ref,
    defineProps,
    defineEmits
} from "vue";

const props = defineProps({
    inputName: { type: String, default: ("input" + String(Date.now()).substring(8)) },
    label: String,
    modelValue: Number,
    units: String,
    min: { type: Number, default: 1 },
    max: { type: Number, default: 100 },
    step: { type: Number, default: 1 },
});

const emit = defineEmits(['input']);
const value = ref(props.modelValue);

// eslint-disable-next-line
emit('input', value);
</script>

<script>
export default {
  name: 'RangeInput',
}
</script>

<style>
.RangeInputValues {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 16px;
}
.RangeLabel {
  width: 50%;
}
.RangeValue {
  width: 50%;
  text-align: right;
}
.RangeInput {
  display: block;
  width: 100%;
}
</style>
