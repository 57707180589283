<template>
  <div class="DialogFadeBg" v-if="visible">
    <div class="TextPreviewDialog overflow-auto">
      <div class="TextPreviewText" v-html="textToShow"/>
    </div>
    <div class="TextPreviewToolbar">
      <close-button class="TextPreviewClose" @click="$emit('close')"/>
    </div>
  </div>
</template>

<script>
import CloseButton from "@/components/Buttons/CloseButton.vue";

export default {
  name: "TextPreviewDialog",
  components: {CloseButton},
  props: {
    visible: {type: Boolean, default: false},
    text: {type: String, default: ''},
    url: {type: String, default: ''},
  },
  data() {
    return {
      textToShow: ''
    }
  },
  watch: {
    async url(value) {
      if (!value) {
        this.textToShow = '';

        return;
      }

      const response = await fetch(value);

      if (response.status === 200) {
        this.textToShow = await response.text();
      } else {
        this.textToShow = 'Не удалось загрузить данные';
      }
    }
  }
}
</script>

<style scoped>
.DialogFadeBg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
}

.TextPreviewDialog {
  display: flex;
  position: absolute;
  padding: 16px 24px;
  top: 16px;
  left: 16px;
  right: 16px;
  bottom: 96px;
  z-index: 100;
  border-radius: 6px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
  background: white;
}

.TextPreviewToolbar {
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.TextPreviewText {
  display: block;
  margin-bottom: 32px;
  font-size: 12px;
  height: 100%;
}

.TextPreviewText h1 {
  font-size: 24px;
  font-weight: 600;
}

.TextPreviewText h4 {
  font-size: 20px;
  font-weight: 600;
}

</style>
