<template>
  <div class="PdBlock Padded">
    <div class="FaqTitle">Частые вопросы</div>
    <accordion-item v-for="(item, index) in faqItems" :key="index" :title="item.title" :text="item.text" />
  </div>
</template>

<script>
import AccordionItem from "@/views/8_StepPlanPreview/components/View/AccordionItem.vue";
import {FAQ_ITEMS} from "@/entities/consts";

export default {
  name: "FaqBlock",
  components: {AccordionItem},
  data() {
    return {
      faqItems: FAQ_ITEMS,
    }
  }
}
</script>

<style scoped>
.FaqTitle {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}
</style>
