<template>
  <div :class="listClasses">
    <SurveyItem v-for="item in items"
                :key="item.id"
                v-bind="item"
                :is-active="item.id === activeItemId"
                @click-item="() => setItemId(item)"/>
  </div>
</template>

<script setup>
import {computed, ref, defineProps, defineEmits} from "vue";
import {SurveyType} from "@/entities/types";
import SurveyItem from "@/components/Survey/SurveyItem.vue";

const props = defineProps({
  type: {type: [String, Number], default: ''},
  items: {type: Array, default: () => []},
});

const activeItemId = ref();

const listClasses = computed(() => {
    const {type} = props;
    const result = ["SurveyList", "FlexColumn"];

    if (type === SurveyType.image) result.push("image");
    if (type === SurveyType.text) result.push("text");
    if (type === SurveyType.textImage) result.push("textImage");

    return result.join(" ");
});

const emit = defineEmits(['onItemClick']);

function setItemId(item) {
  activeItemId.value = item.id;

  emit('onItemClick', item);
}

</script>

<style scoped>
.FlexColumn {
  display: flex;
  flex-direction: column;
}

.SurveyList {
  margin: 24px;
  gap: 24px;
}

.SurveyList.image {
  /*     border: 4px solid pink; */
}

.SurveyList.text {
  border: 4px dashed cyan;
}

.SurveyList.textImage {
  /*     border: 4px dotted violet; */
}
</style>
