<template>
  <div class="ProofItem">
    <span class="ProofItemText">{{ item.text }}</span>
    <span class="ProofItemAuthor">{{ item.name }}</span>
  </div>
</template>

<script>
export default {
  name: "ProofItem",
  props: {
    item: {
      type: Object, default: () => ({}),
    }
  }
}
</script>

<style scoped>
.ProofItem {
  display: flex;
  flex-direction: column;
  gap: 16px;
  /*min-width: 340px;*/
  width: 100%;
  padding: 24px;
  background: var(--color-black0);
  border: 1px solid var(--color-black10);
  border-radius: 6px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}
.ProofItemText {
  font-style: italic;
}
.ProofItemAuthor {
  text-align: right;
  font-weight: bold;
}
</style>
