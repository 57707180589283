<template>
  <div class='GenderSelector'>
    <div class='GenderBgImage'/>
    <div class='GenderArrows'>
      <div class="GenderArrow Left"/>
      <div class="GenderArrow Right"/>
    </div>
    <div class='GenderButtons'>
      <RoundButton v-bind="MaleBtnProps"
                   :active="QuizStore.gender === Gender.Male"
                   @click="() => select(Gender.Male)"
                   :classes="'GenderMale'"/>
      <RoundButton v-bind="FemaleBtnProps"
                   :active="QuizStore.gender === GenderType.Female"
                   @click="() => select(GenderType.Female)"
                   :classes="'GenderMale'"/>
    </div>
  </div>
</template>

<script setup>
import RoundButton from "@/components/Buttons/RoundButton.vue";
import {FEMALE_BTN, MALE_BTN} from "@/entities/consts";
import {Gender, QuizStep} from "@/entities/types";
import {useQuizStore} from "@/app/stores/QuizStore";
import {ref} from "vue";
import {useRouter} from "vue-router";

const QuizStore = useQuizStore();
const router = useRouter();

const MaleBtnProps = ref(MALE_BTN);
const FemaleBtnProps = ref(FEMALE_BTN);
const GenderType = ref(Gender);

QuizStore.initPlan().then(() =>
    QuizStore.updateValues({step: QuizStep.SelectGender})
);

function select(gender) {
  (QuizStore).updateValues({gender, step: QuizStep.Target});
  setTimeout(() => router.push('/target'), 500);
}
</script>

