import { defineStore} from "pinia";
import {RootState} from "@/entities/types";

export const useQuizStore = defineStore("QuizStore", {
    state: () => RootState,
    actions: {
        async initPlan() {
            this.updateValues(RootState);
            // this.planId = uuidv4();
        },
        async updateValues(values) {
            Object.keys(values).forEach(key => {
                this[key] = values[key];
            });
        },
        addToCart(item) {
            this.cart.push(item);
        }
    }
});

