<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.FlexCenterContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

:root {
  --color-primary0: #EAF7F6;
  --color-primary5: #C4ECE9;
  --color-primary10: #A0E0DB;
  --color-primary20: #62C9C1;
  --color-primary30: #33B3A8;
  --color-primary40: #f8fffe;
  --color-primary50: #00857A;
  --color-primary60: #006E65;
  --color-primary70: #00574F;
  --color-primary80: #00403A;
  --color-primary90: #002925;
  --color-primary100: #001210;
  --color-secondary0: #FFF7DF;
  --color-secondary5: #FEF0C2;
  --color-secondary10: #FEE9A6;
  --color-secondary20: #FCDB6F;
  --color-secondary30: #F8CD3E;
  --color-secondary40: #F4C017;
  --color-secondary50: #EEB600;
  --color-secondary60: #E5B000;
  --color-secondary70: #DBA800;
  --color-secondary80: #CF9F00;
  --color-secondary90: #C19400;
  --color-secondary100: #B38900;
  --color-black0: #FFFFFF;
  --color-black5: #F5F5F5;
  --color-black10: #E8E8E8;
  --color-black15: #DBDBDB;
  --color-black20: #C2C2C2;
  --color-black30: #A8A8A8;
  --color-black40: #8F8F8F;
  --color-black50: #757575;
  --color-black60: #5C5C5C;
  --color-black70: #424242;
  --color-black80: #292929;
  --color-red0: #FFE8EA;
  --color-red5: #FED0D4;
  --color-red10: #FDB8BF;
  --color-red20: #F98994;
  --color-red30: #F45F6E;
  --color-red40: #EC3E4F;
  --color-red50: #E22336;
  --color-red60: #D41124;
  --color-red70: #C30417;
  --color-red80: #AF0011;
  --color-red90: #97000F;
  --color-red100: #80000D;
  --color-orange0: #FFF2EE;
  --color-orange5: #FEE3D9;
  --color-orange10: #FED3C5;
  --color-orange20: #FBB49C;
  --color-orange30: #F89878;
  --color-orange40: #F38059;
  --color-orange50: #EC6C42;
  --color-orange60: #E45D2F;
  --color-orange70: #D95022;
  --color-orange80: #CC4519;
  --color-orange90: #BD3D12;
  --color-orange100: #AD360E;
  --color-blue0: #F0F8FF;
  --color-blue5: #D7EDFD;
  --color-blue10: #BFE1FC;
  --color-blue20: #8ECAF9;
  --color-blue30: #54A8EA;
  --color-blue40: #1B6BAC;
  --color-blue50: #0F4C81;
  --color-blue60: #07335A;
  --color-blue70: #03223E;
  --color-blue80: #01192F;
  --color-pink0: #FFEFF4;
  --color-pink5: #FED4E0;
  --color-pink10: #FCB9CD;
  --color-pink20: #F887A8;
  --color-pink30: #F05B87;
  --color-pink40: #E6376B;
  --color-pink50: #D81B54;
  --color-pink60: #C50D48;
  --color-pink70: #AE053F;
  --color-pink80: #920136;
  --color-purple5: #EADCEF;
  --radius: 8px;
  --greyF8: #f8f8f8;
  --greyEa: #eaeaea;
  --greyCf: #cfcfcf;
  --grey9: #999999;
  --grey6: #666666;
  --white: #ffffff;
  --black: #000000;
  --turquoise: #cff4fc;
  --yellow: #fff3cd;
  --pink: #f8d7da;
  --green: #63cb11;
  --purple: #cb11ab;
  --blue: #005bff;
  --blueDark: #1150cb;
  --shadow: rgba(0, 0, 0, 0.15);
  --transparent06: rgba(255, 255, 255, 0.6);
  --transparent08: rgba(255, 255, 255, 0.8);
}

</style>
