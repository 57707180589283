<template>
  <block-view>
    <div class="BodyIndexLabels">
      <span class="BodyIndexLabel">Текущий ИМТ</span>
      <span class="BodyIndexInfo" @click="showModal">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6818 14.6667 7.99992C14.6667 4.31802 11.6819 1.33325 8.00004 1.33325C4.31814 1.33325 1.33337 4.31802 1.33337 7.99992C1.33337 11.6818 4.31814 14.6666 8.00004 14.6666Z" stroke="#5C5C5C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 10.6667V8" stroke="#5C5C5C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 5.33325H8.00667" stroke="#5C5C5C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg> Что такое ИМТ?</span>
    </div>
    <body-index-progress :value="index" />
  </block-view>
  <body-index-modal :show="modalVisible" @close="hideModal" />
</template>

<script>
import BlockView from "@/views/8_StepPlanPreview/components/View/BlockView.vue";
import BodyIndexProgress from "@/views/8_StepPlanPreview/components/Body/BodyIndexProgress.vue";
import BodyIndexModal from "@/views/8_StepPlanPreview/components/Body/BodyIndexModal.vue";

export default {
  name: "BodyIndex",
  components: {BodyIndexModal, BodyIndexProgress, BlockView},
  props: {
    index: {
      type: [Number, String], default: 0
    }
  },
  data() {
    return {
      modalVisible: false,
    }
  },
  methods: {
    showModal() {
      this.modalVisible = true;
    },
    hideModal() {
      this.modalVisible = false;
    }
  }
}
</script>

<style scoped>
.BodyIndexLabels {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.BodyIndexLabel {
  font-weight: 600;
  font-size: 16px;
}

.BodyIndexInfo {
  display: flex;
  text-align: right;
  align-items: center;
  justify-content: right;
  color: #5c5c5c;
  gap: 4px;
  font-size: 14px;
  cursor: pointer;
}
</style>
