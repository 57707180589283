<template>
  <div class="StepTarget PageStep">
    <page-view title="Ваша цель" subtitle="Какую цель нужно достичь?">
      <survey-list v-bind="SURVEY_TARGET_PROPS" @on-item-click="clickTarget"/>
    </page-view>
  </div>
</template>
<script setup>

import PageView from "@/components/Layouts/PageView/PageView.vue";
import SurveyList from "@/components/Survey/SurveyList.vue";
import {SURVEY_TARGET_PROPS as TARGET_PROPS} from "@/entities/consts";
import {useQuizStore} from "@/app/stores/QuizStore";
import {ref} from "vue";
import {useRouter} from "vue-router";
import {QuizStep} from "@/entities/types";

const SURVEY_TARGET_PROPS = ref(TARGET_PROPS);
const QuizStore = useQuizStore();
const router = useRouter();

QuizStore.updateValues({step: QuizStep.Target});

function clickTarget(item) {
  QuizStore.updateValues({ target: item.id, step: QuizStep.Measures });
  setTimeout( () => router.push('/measures'), 500);
}

</script>
<script>
export default {name: 'StepTarget'};
</script>
