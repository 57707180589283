<template>
  <div class="Calculator">
    <p class="CalculatorText">{{ text }}</p>
    <div class="CalculatorBar">
      <svg class="round" viewbox="0 0 0 0" width="300" height="300" :style="circleStyle">
        <circle cx="150" cy="150" r="100"/>
      </svg>
    </div>
    <div class="CalcProgress">{{ progress }}<span>%</span></div>
  </div>
</template>

<script setup>
import {onMounted, ref, defineEmits} from "vue";
// import {useRouter} from "vue-router";
import {CalcMessages} from "@/entities/consts";

const emit = defineEmits(['onComplete']);
const progress = ref(0);
const textGen = pullText();
const text = ref(textGen.next().value);
// eslint-disable-next-line no-undef
const props = defineProps({
  forceCompleted: { type: Boolean, default: false }
})

let timeout;
const MAX_PROGRESS = 100;
// const router = useRouter();
const showMessageAfter = Math.floor(MAX_PROGRESS / (CalcMessages.length-1));

onMounted(() => {
  tickOn();
  updateCircle();
})

function* pullText() {
  for (let i = 0; i < CalcMessages.length; i++) {
    yield CalcMessages[i].label;
  }
}

const circleStyle = ref({});

function updateCircle() {
  // анимация
  const obj = document.querySelector('circle');
  const radius = obj.getAttribute('r');
  const circum = 2 * radius * Math.PI;
  const len = Math.ceil(circum * progress?.value  / MAX_PROGRESS);

  circleStyle.value['stroke-dasharray'] = len + ' 999';
}

function tickOn() {
  clearInterval(timeout);

  if (progress.value < MAX_PROGRESS) {
    progress.value = progress.value + 1;

    if (props.forceCompleted) {
      progress.value = MAX_PROGRESS;
    }

    if (progress.value % showMessageAfter === 0) {
      updateCircle();

      text.value = textGen.next().value;
    }
    timeout = setInterval(tickOn, 100);
  } else {
    emit('onComplete');
  }
}
</script>

<script>
export default {
  name: 'CalculatorOutput'
}
</script>

<style scoped>

.Calculator {
  margin-top: 50px;
  min-height: 250px;
  position: relative;
}

.CalculatorText {
  display: flex;
  min-height: 60px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 16px;
}

.CalcProgress {
  font-size: 48px;
  text-align: center;
  position: relative;
  transform: translateY(-190px);
  right: 0;
  left: 0;
  transition: all 1s ease-in-out;
}

.loader-percents span {
  font-size: 24px;
  color: #999;
}

.CalculatorBar {
  display: flex;
  position: relative;
  justify-content: center;
}

.round {
  transform: rotate(+91deg);
  transition: all 1s ease-in-out;

  /* SVG */
  fill: none;
  stroke: #5ca2ff;
  stroke-width: 16;
  stroke-linecap: round;
  stroke-dasharray: 0 999;
}
</style>
