<template>
  <div class="PageViewFooter">
    <div class="PageViewBackButton" v-if="showBack" @click="$emit('onBackClick')">
      <img class="backArrow" src="/assets/img/arrow-back.png"> Назад
    </div>
    <div class="PageViewFooterItems">
      <FooterItem v-for="item in FOOTER_STEPS" :key="item" :step="item"/>
    </div>
  </div>
</template>

<script>
import {FOOTER_STEPS} from "@/entities/consts";

export default {
  props: {
    showBack: { type: Boolean, default: false },
  },
  data() {
    return {
      FOOTER_STEPS,
    }
  }
}
</script>

<style scoped>
.PageViewFooter {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.PageViewFooterItems {
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: space-evenly;
  align-items: center;
  position: sticky;
  padding: 6px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: #f8f8f8;
}

@media (max-width: 420px) {
  .PageViewFooter {
    /*position: absolute;*/
  }
}

</style>
<script setup>
import FooterItem from "@/components/Layouts/FooterItem/FooterItem.vue";
</script>
