<template>
  <div class="copyrights">{{ owner }} &reg; {{ year }}<br/>Все права защищены</div>
</template>

<script>
export default {
  name: "CopyrightsBlock",
  data() {
    return {
      owner: 'PlanBerry.ru',
      year: (new Date()).getFullYear(),
    }
  }
}
</script>

<style scoped>
.copyrights {
  padding: 32px 0px 124px;
  color: var(--color-black60);
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin: 0px auto;
  max-width: 300px;
}
</style>
