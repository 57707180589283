<template>
  <div class="PageView">
    <PageViewHeader :subtitle="subtitle">{{ title }}</PageViewHeader>

    <div class="PageViewBody">
      <slot/>
    </div>

    <PageViewFooter :show-back="showBack" @on-back-click="backButtonHandler"/>
  </div>
</template>

<script setup>
import {defineProps} from 'vue';
import PageViewFooter from "@/components/Layouts/PageView/PageViewFooter.vue";
import {useRouter} from "vue-router";
import PageViewHeader from "@/components/Layouts/PageView/PageViewHeader.vue";

const router = useRouter();

defineProps({
  title: {type: String, default: null},
  subtitle: {type: String, default: null},
  showBack: { type: Boolean, default: true},
});

function backButtonHandler() {
  router.back();
}
</script>

<style>
.PageStep {
  min-height: 100%;
  height: 100%;
}

.PageView {
  display: grid;
  grid-template-rows: auto 1fr auto;
  position: relative;
  min-height: 100%;
}

.PageViewHeader {
  flex-shrink: 0;
  flex-grow: 0;
  background: white;
  border-bottom: 1px solid #bdbdbd;
}

.PageViewBody {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-top: 1px solid #f0f0f0;
  /*max-height: 100vh;*/
  overflow-y: auto;
}

.PageViewBackButton {
  display: flex;
  align-items: center;
  justify-content: center;
  /*position: absolute;*/
  /*top: 24px;*/
  /*left: 16px;*/
  /*width: 32px;*/
  /*height: 32px;*/
  line-height: 1.1;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  gap: 12px;
  padding: 8px 16px;
  /*border-radius: 50%;*/
  color: #444444;
  background-color: var(--color-black10);
  cursor: pointer;
}
.backArrow {
  max-width: 24px;
  max-height: 24px;
}


@media (max-width: 420px) {
  .PageViewHeader {
    display: block;
    /*position: absolute;*/
    top: 0;
    left: 0;
    right: 0;
  }

  .PageView {
    height: 100vh;
  }
}
</style>
