<template>
  <div>
    <div class="PlanItemMessage" v-if="message">{{ message }}</div>
    <div class="PlanItem" :class="{'Active': isActive, 'withMessage': !!message }">
      <div class="PlanItemContainer">

        <div class="PlanItemBlock">
          <div class="PlanItemSelect">
            <div class="PlanItemSelect Active" v-if="isActive"></div>
          </div>
          <div class="PlanItemLabel">
            <div class="PlanItemName">{{ name }}</div>
            <div class="PlanItemPrices">
              <div class="PlanItemPrice Old">{{ price_old }} {{ currency }}</div>
              <div class="PlanItemPrice">{{ price }} {{ currency }}</div>
            </div>
          </div>
        </div>

        <div class="PlanItemNumbers">
          <div class="PlanItemPrice Daily">{{ price_per_day }}<sup>{{ currency }}</sup></div>
          <div class="PlanItemPrice">в день</div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlanItem",
  props: {
    name: String,
    message: String,
    currency: String,
    price: Number,
    price_old: Number,
    price_per_day: Number,
    isActive: Boolean,
  }
}
</script>

<style scoped>
.PlanItem {
  border-radius: 8px;
  overflow: hidden;
  color: var(--color-black80);
  background-color: var(--color-black0);
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  border: 2px solid white;
}

.PlanItem.Active {
  border: 2px solid var(--color-primary50);
}

.PlanItem.Active.withMessage {
  border: 2px solid var(--color-primary50);
}

.PlanItem.withMessage {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.PlanItemContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 18px 24px 14px 18px;
}

.PlanItemBlock {
  display: flex;
  gap: 16px;
}

.PlanItemLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PlanItemName {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: var(--color-black80);
  margin-bottom: 4px;
}

.PlanItemPrices {
  display: flex;
  gap: 4px;
}

.PlanItemPrice {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-black40);
}

.PlanItemPrice.Old {
  text-decoration: line-through;
}

.PlanItemPrice.Daily {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: var(--color-black80);
}

.PlanItemPrice.Daily sup {
  /*font-size: 14px;*/
  font-weight: 400;
}

.PlanItemNumbers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.PlanItemSelect {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--color-primary50);
}

.PlanItemSelect.Active {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--color-primary50);
}

.PlanItemMessage {
  display: block;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: var(--color-primary50);
  color: var(--color-black0);
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
}
</style>
