<template>
  <div class="AccordionItem">
    <div class="AccordionItemHeader">
      <div class="AccordionItemTitle">{{ title }}</div>
      <div class="AccordionItemCloser" @click="isExpanded = !isExpanded">{{ isExpanded ? '&minus;' : '&plus;' }}</div>
    </div>
    <div class="AccordionItemBody" :class="{'Collapsed': !isExpanded }">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "AccordionItem",
  props: {
    title: String,
    text: String,
  },
  data() {
    return {
      isExpanded: false,
    }
  }
}
</script>

<style scoped>
.AccordionItem {
  padding: 24px;
  border-bottom: 1px solid var(--color-black10);
}
.AccordionItemHeader {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.AccordionItemTitle {
  font-size: 20px;
  font-weight: bold;
}
.AccordionItemBody {
  width: 100%;
  height: 100%;
  transition: all .5s linear;
  overflow: hidden;
}

.AccordionItemBody.Collapsed {
  max-height: 1px;
  height: 1px;
  transition: all .5s linear;
}
.AccordionItemCloser {
  font-size: 32px;
  line-height: 1;

}
</style>
