<template>
  <div className="StepPlanPreview PageStep">
    <page-view
        title="Ваша активность"
        subtitle="Влияет на скорость обмена веществ"
    >
      <survey-list v-bind="SURVEY_ACTIVITY_PROPS" @on-item-click="clickTarget"/>
    </page-view>
  </div>
</template>

<script setup>
import SurveyList from "@/components/Survey/SurveyList.vue";
import {useRouter} from "vue-router";
import {SURVEY_ACTIVITY_PROPS} from "@/entities/consts";
import PageView from "@/components/Layouts/PageView/PageView.vue";
import {QuizStep} from "@/entities/types";
import {useQuizStore} from "@/app/stores/QuizStore";

const router = useRouter();
const QuizStore = useQuizStore();

QuizStore.updateValues({step: QuizStep.Activity});

function clickTarget(item) {
  QuizStore.updateValues({ activity: item.id, step: QuizStep.Calculation });
  setTimeout( () => router.push('/calculation'), 500);
}
</script>
