<template>
  <div class="GenderFooter">
    <div class="GenderFooterPolicy">
      <LinkButton title="Условия использования" @click="urlToShow = URL_CONDITIONS"/>
      <LinkButton title="Политика конфиденциальности" @click="urlToShow = URL_POLICY"/>
    </div>
    <div class="GenderFooterLegacy">
      &copy; ИП Тихонов Максим Борисович, {{ year }}<br/>ИНН 773714308801 ОГРНИП 322774600075441
    </div>
  </div>
  <TextPreviewDialog :url="urlToShow" :visible="!!urlToShow" @close="urlToShow = ''"/>
</template>

<script>
import LinkButton from "@/components/Buttons/LinkButton.vue";
import TextPreviewDialog from "@/components/Dialogs/TextPreviewDialog.vue";
import {URL_CONDITIONS, URL_POLICY} from "@/entities/consts";

export default {
  name: "GenderFooter",
  components: {TextPreviewDialog, LinkButton},
  data() {
    return {
      urlToShow: '',
      URL_CONDITIONS,
      URL_POLICY,
    }
  },
  computed: {
    year() {
      return (new Date()).getFullYear();
    }
  }
}
</script>

<style scoped>
.GenderFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.GenderFooterPolicy {
  display: flex;
  gap: 16px;

}

.GenderFooterLegacy {
  text-align: center;
  padding: 6px 12px;
  font-size: 12px;
  color: gray;
}
</style>
