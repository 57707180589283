<template>
  <div class="BodyIndexProgress">
    <div class="BodyIndexProgressValue">{{ value }}</div>
    <div class="BodyIndexProgressText">
      Ваш вес: {{ text }}
    </div>
    <div class="BodyIndexProgressContainer">
      <div class="BodyIndexProgressIndicator"></div>
      <div class="BodyIndexProgressPosition" :style="positionStyle"></div>
    </div>
    <div class="BodyIndexProgressLabels">
      <div class="BIPLabel">Недовес</div>
      <div class="BIPLabel">Норма</div>
      <div class="BIPLabel">Ожирение</div>
    </div>
  </div>
</template>

<script>
import {BMI} from "@/entities/consts";

export default {
  name: "BodyIndexProgress",
  props: {
    value: {
      type: [Number, String], default: ''
    }
  },
  data() {
    return {
      color: 'white',
      text: 'не определен',
    }
  },
  mounted() {
    const {value} = this;
    const result = BMI.filter(el => value > el[0] && value < el[1]).pop();

    this.text = result[2];
    this.color = result[3];
  },
  computed: {
    positionStyle() {
      const position = this.value / 50 * 100
      return {
        // 62C9C1 green
        //  yellow
        //  red
        backgroundColor: this.color,
        left: `${position}%`,
      }
    }
  }
}
</script>

<style scoped>
.BodyIndexProgressContainer {
  position: relative;
}

.BodyIndexProgressIndicator {
  display: flex;
  position: relative;
  min-height: 12px;
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(90deg,
  var(--color-primary20) 0%,
  var(--color-secondary10) 47.51%,
  var(--color-red10) 97.97%);
  margin: 6px 0px;
}

.BodyIndexProgressValue {
  font-size: 32px;
  font-weight: 600;
}

.BodyIndexProgressPosition {
  display: block;
  position: absolute;
  border-radius: 100%;
  top: -6px;
  width: 24px;
  height: 24px;
  border: 2px solid white;
  background: white;
  font-size: 18px;
  font-weight: 600;
}
.BodyIndexProgressLabels {
  display: flex;
  justify-content: space-between;
}
.BIPLabel {
  font-size: 12px;
  color: #757575;
}
</style>
