<template>
  <div class="StepPayment PageStep">
    <page-view
        title="Скачивание плана"
        subtitle="Чтобы скачать Ваш персональный план питания оплатите его удобным способом"
        :show-back="false"
    >
      <div class="Cart">
        <div class="CartHeader">Ваш заказ</div>
        <div class="CartTotal">{{ total.new }} ₽</div>
        <div class="CartItem" v-for="(item, index) in store.cart" :key="index">
          <div class="CartItemName">{{ item.name }}</div>
          <div class="CartItemPrice">{{ item.new_price }} ₽</div>
        </div>
        <br/>
        <div class="BasicButton">Перейти к оплате</div>
      </div>
    </page-view>
  </div>
</template>

<script setup>
  import {useQuizStore} from "@/app/stores/QuizStore";
  import PageView from "@/components/Layouts/PageView/PageView.vue";
  import {computed} from "vue";

  const store = useQuizStore();

  const total = computed(() => {
    const result = { new: 0, old: 0 }
    store.cart.forEach(el => {
      result.new += el.new_price;
      result.old += el.old_price;
    })
    return result
  })
</script>

<style>
.Cart {
  padding: 24px;
}
.CartHeader {
  font-size: 12px;
}
.CartTotal {
  font-size: 64px;
  font-weight: 500;
}
.CartItem {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 0;
  border-bottom: 1px dashed silver;
}
</style>
