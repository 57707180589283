import {createWebHistory, createRouter} from "vue-router";
import PageLayout from "@/components/Layouts/PageLayout.vue";
import StepGender from "@/views/1_StepGender/StepGender.vue";
import StepTarget from "@/views/2_StepTarget/StepTarget.vue";
import StepMeasures from "@/views/3_StepMeasures/StepMeasures.vue";
import StepBodyTypes from "@/views/4_StepBodyTypes/StepBodyTypes.vue";
import StepActivity from "@/views/5_StepActivity/StepActivity.vue";
import StepFoods from "@/views/6_StepFoods/StepFoods.vue";
import StepCalculation from "@/views/7_StepCalculation/StepCalculation.vue";
import StepPlanPreview from "@/views/8_StepPlanPreview/StepPlanPreview.vue";
import StepUpsell from "@/views/8_StepUpsell/8_StepUpsell.vue";
import StepPayment from "@/views/9_StepPayment/StepPayment.vue";
import StepCompleted from "@/views/10_StepCompleted/StepCompleted.vue";
// import ErrorPage from "@/views/ErrorPage/ErrorPage.vue";
import {QuizStep, QuizStepValues} from "@/entities/types";
import TestPage from "@/views/TestPage/TestPage.vue";
import {useQuizStore} from "@/app/stores/QuizStore";

const routes = [
    {
        path: "/",
        component: PageLayout,
        beforeEnter: (to, from, next) => {
            const {step} = useQuizStore();

            const stepIndex = QuizStepValues.indexOf(step);
            const pathIndex = QuizStepValues.indexOf(to.path.substr(1));

            if (stepIndex === -1 && pathIndex === -1) {
                next();
            } else if (stepIndex >= 0 && stepIndex < pathIndex)
                next();
            else
                next('/');
        },
        children: [
            {
                path: "",
                component: StepGender,
            },
            {
                path: "test",
                component: TestPage,
            },
            {
                path: QuizStep.Target,
                component: StepTarget,
            },
            {
                path: QuizStep.Measures,
                component: StepMeasures,
            },
            {
                path: QuizStep.BodyType,
                component: StepBodyTypes,
            },
            {
                path: QuizStep.Activity,
                component: StepActivity,
            },
            {
                path: QuizStep.FoodSelect,
                component: StepFoods,
            },
            {
                path: QuizStep.Calculation,
                component: StepCalculation,
            },
            {
                path: QuizStep.PlanPreview,
                component: StepPlanPreview,
            },
            {
                path: QuizStep.Upsell,
                component: StepUpsell,
            },
            {
                path: QuizStep.Payment,
                component: StepPayment,
            },
            {
                path: QuizStep.Completed,
                component: StepCompleted,
            },
            {
                path: "/:catchAll(.*)",
                redirect: '/'
            },
        ]
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router;
