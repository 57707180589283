<template>
  <div class="FooterItem">
    <div class="FooterItemDot" :class="stateClassname"></div>
  </div>
</template>

<script>
import {useStepActive, useStepCompleted} from "@/entities/hooks";

export default {
  name: "FooterItem",
  props: {
    step: {type: String, default: ''},
  },
  computed: {
    stateClassname() {
      return {
        completed: useStepCompleted(this.step),
        active: useStepActive(this.step),
      };
    }
  }
}
</script>

<style scoped>
.FooterItem {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 32px;
  height: 32px;
  padding: 0px;
  border-radius: 32px;
  /*border: 1px solid #e0e0e0;*/
}
.FooterItem .completed {
  background: none;
}

.FooterItemDot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 25%;
  border-radius: 100%;
  background: var(--color-red10);
  /*border-top: 2px solid #90ee90;*/
  /*border-left: 2px solid #90ee90;*/
  /*border-bottom: 2px solid #187a18;*/
  /*border-right: 2px solid #187a18;*/
}

.FooterItemDot.active,
.FooterItemDot.completed {
  transition: all 0.3s linear;
  width: 100%;
  height: 100%;
  background-color: var(--color-secondary20);
}

.FooterItemDot.completed {
  width: 82%;
  height: 82%;
  background-color: var(--color-primary30);
}

</style>
