<template>
<div class="BtnToolbar">
    <common-button :label="`Далее`" @click="$emit('click')" />
</div>
</template>

<script setup>
  import CommonButton from "@/components/Buttons/CommonButton.vue";

</script>

<script>
export default {
  name: "BtnToolbar"
}
</script>

<style>
.BtnToolbar {
    display: flex;
    width: 100%;
    gap: 16px;
    align-items: center;
    justify-content: center;
}
</style>
