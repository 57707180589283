<template>
  <div :class="btnClasses">{{ title }}</div>
</template>

<script>
export default {
  name: "LinkButton",
  props: {
    title: { type: String, default: '' },
    classes: { type: String, default: '' },
  },
  computed: {
    btnClasses() {
      return ["LinkButton", this.classes].join(" ");
    }
  }
}
</script>

<style scoped>
.LinkButton {
  padding: 6px 12px;
  color: blue;
  font-size: 12px;
  text-align: center;
  text-decoration: underline;
}
</style>
