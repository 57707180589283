<template>
  <div :class="btnClasses" :style="btnStyle" @click="onClick">
    {{ label }}
  </div>
</template>

<script>

export default {
  name: "RoundButton",
  props: {
    label: {type: String, default: ''},
    labelColor: {type: String, default: ''},
    bgColor: {type: String, default: ''},
    classes: {type: String, default: ''},
    active: {type: Boolean, default: false},
    onClick: {
      type: Function, default: () => {
      }
    },
  },
  computed: {
    btnStyle() {
      return {
        color: this.labelColor || 'white',
        backgroundColor: this.bgColor || 'gray',
      }
    },
    btnClasses() {
      return ["RoundButton", this.classes, (this.active ? "active" : "")].join(" ");
    }
  }
}
</script>

<style scoped>
.RoundButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  border: 10px solid rgba(255, 255, 255, 0.3);
  background: gray;
  color: white;
  transition: all 0.15s ease-in-out;
  opacity: 0.9;
}

.RoundButton:hover {
  scale: 110%;
  opacity: 1;
}

.RoundButton.active {
  scale: 120%;
  opacity: 1;
}
</style>
