<template>
  <div class="CloseButton">
    <div class="CloseButtonCloser">&times;</div>
  </div>
</template>

<script>
export default {
  name: "CloseButton"
}
</script>

<style scoped>
.CloseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid white;
  padding: 0;
  width: 64px;
  height: 64px;
  color: white;
}

.CloseButtonCloser {
  font-size: 36px;
  line-height: 1;
  margin-bottom: 6px;
}
</style>
