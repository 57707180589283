<template>
  <div className="StepMeasures PageStep">
    <page-view
        title="Параметры тела"
        subtitle="Укажите Ваши параметры">
      <div class="Measures">
        <range-input
            v-for="item in measureItems"
            :key="item.inputName"
            :label="item.label"
            :units="item.units"
            :min="item.min"
            :max="item.max"
            :model-value="item.value"
            @input="applyMeasure(item, $event)">

        </range-input>
        <btn-toolbar class="mt-3" @click="next"/>
      </div>

    </page-view>
  </div>
</template>

<script setup>
// eslint-disable-next-line
import PageView from "@/components/Layouts/PageView/PageView.vue";

// eslint-disable-next-line
import RangeInput from '@/components/Inputs/RangeInput.vue';
import {MEASURE_ITEMS} from "@/entities/consts";
import {ref} from "vue";
import {useQuizStore} from '@/app/stores/QuizStore';
import BtnToolbar from "@/components/Buttons/BtnToolbar.vue";
import {QuizStep} from "@/entities/types";
import {useRouter} from "vue-router";

// eslint-disable-next-line
const measureItems = ref(MEASURE_ITEMS);
const QuizStore = useQuizStore();

QuizStore.updateValues({step: QuizStep.Measures});

// eslint-disable-next-line no-unused-vars
function applyMeasure(item, value) {
  // eslint-disable-next-line no-debugger
  const measures = QuizStore.measures;
  measures[item.inputName] = value;
  QuizStore.updateValues({ measures });
}

const router = useRouter();

const next = () => {
  QuizStore.updateValues({ step: QuizStep.BodyType });
  setTimeout(() => router.push("/body-type"), 500);
}
</script>

<style>
.Measures {
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
}
</style>
