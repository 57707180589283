<template>
  <div class="StepPlanPreview PageStep">
    <page-view
        title="Ваше телосложение"
        subtitle="Определяет уровень метаболизма"
    >
      <survey-list v-bind="SURVEY_BODY_TYPES" @on-item-click="clickTarget"/>
    </page-view>
  </div>
</template>
<script setup>

import PageView from "@/components/Layouts/PageView/PageView.vue";
import SurveyList from "@/components/Survey/SurveyList.vue";
import {SURVEY_BODY_TYPES_PROPS as TARGET_PROPS} from "@/entities/consts";
import {useQuizStore} from "@/app/stores/QuizStore";
import {ref} from "vue";
import {useRouter} from "vue-router";
import {QuizStep} from "@/entities/types";

const SURVEY_BODY_TYPES = ref(TARGET_PROPS);

const router = useRouter();
const QuizStore = useQuizStore();

QuizStore.updateValues({step: QuizStep.BodyType});

function clickTarget(item) {
  QuizStore.updateValues({bodyType: item.id, step: QuizStep.Activity});

  setTimeout(() => router.push('/activity'), 500);
}
</script>
<script>
export default {name: 'StepTarget'};
</script>
