import {BodyType, QuizStep, SurveyType, WeightTarget} from "./types";

// FIXME вынести сюда заголовки из всех шагов
export const SLOGAN = {
    // title: "Ешь, худей, копи",
    title: "Выберите свой пол",
    // subtitle: "Худей вкусно и экономь до 50% бюджета на продукты"
    subtitle: "Худеем вкусно и экономим до 50% бюджета на продукты"
};

const COLORS = {
    btnMale: "#41d5e4",
    btnFemale: "#ef91da",
    btnSelected: "#00AA3A"
}

export const MALE_BTN = {
    label: "Мужчина",
    bgColor: COLORS.btnMale,
};
export const FEMALE_BTN = {
    label: "Женщина",
    bgColor: COLORS.btnFemale,
};

export const ROUTES = [
    "",
    "target",
    "measures",
    "body",
    "activity",
    "calc",
    "preview",
    "payment",
    "completed"
]

export const FOOTER_STEPS = [
    // QuizStep.SelectGender,
    QuizStep.Target,
    QuizStep.Measures,
    QuizStep.BodyType,
    QuizStep.Activity,
    QuizStep.Calculation
];

export const SURVEY_TARGET_PROPS = {
    type: SurveyType.textImage,
    items: [
        {id: WeightTarget.Loose, label: "Похудеть", image: "/assets/img/people/5.png"},
        {id: WeightTarget.Keep, label: "Поддержать вес", image: "/assets/img/people/7.png"},
        {id: WeightTarget.Gain, label: "Набрать вес", image: "/assets/img/people/6.png"},
    ]
}

export const SURVEY_BODY_TYPES_PROPS = {
    type: SurveyType.textImage,
    items: [
        {id: BodyType.Endomorph, label: "Мягкое и округлое", image: "/assets/img/people/002.png"},
        {id: BodyType.Mesomorph, label: "Нормальное", image: "/assets/img/people/001.png"},
        {id: BodyType.Ectomorph, label: "Излишне стройное", image: "/assets/img/people/000.png"},
    ]
}

export const SURVEY_ACTIVITY_PROPS = {
    type: SurveyType.textImage,
    items: [
        {id: 1, label: "Отдыхаю или Сижу дома", image: "/assets/img/people/100.png"},
        {id: 2, label: "Работаю в офисе", image: "/assets/img/people/101.png"},
        {id: 3, label: "Легкий спорт или Прогулки", image: "/assets/img/people/102.png"},
        {id: 4, label: "Тяжелая физическая работа или Профессиональный спорт", image: "/assets/img/people/103.png"},
    ]
}

export const URL_CONDITIONS = "/files/docs/conditions.txt";
export const URL_POLICY = "/files/docs/policy.html";

export const MEASURE_ITEMS = [
    {
        inputName: "age",
        label: "Возраст",
        units: "лет",
        value: 25,
        min: 16,
        max: 90,
    },
    {
        inputName: "height",
        label: "Рост",
        units: "см",
        value: 165,
        min: 110,
        max: 220,
    },
    {
        inputName: "weight",
        label: "Вес",
        units: "кг",
        value: 65,
        min: 40,
        max: 200,
    },
    {
        inputName: "target_weight",
        label: "Желаемый вес",
        units: "кг",
        value: 55,
        min: 40,
        max: 200,
    }
];

export const CalcMessages = [
    {id: 51, label: 'Обработка Ваших ответов'},
    {id: 52, label: 'Анализ Вашего диетического профиля'},
    {id: 53, label: 'Расчет Вашего метаболического возраста'},
    {id: 54, label: 'Адаптация плана к Вашему плотному графику'},
    {id: 55, label: 'Подбор подходящих рецептов'},
    {id: 56, label: 'Ваша индивидуальная программа снижения веса готова!'},
]

export const PROOFS = [
    {
        avatar: '',
        name: 'Екатерина Орлова',
        text: 'Блюда вкусные, готовятся быстро, рецепты понятные. Я похудела на 4 кг меньше чем за неделю. Сначала были сомнения, но в итоге все прошло отлично.',
    },
    {
        avatar: '',
        name: 'Сергей Трифонов',
        text: 'Мы проходили программу вместе с женой, очень удобно, когда заранее знаешь, что готовить. И жена стала лучше готовить.',
    },
    {
        avatar: '',
        name: 'Оля Козлова',
        text: 'Мы с подружками стали вместе худеть и сэкономили за первую неделю в два раза больше, чем потратили на покупку плана! Плюс сразу готовые рецепты, очень удобно, не надо ломать голову что приготовить, и все очень-очень вкусно!!',
    },
]

export const ROTATED_DISHES = [
    {src: '/assets/img/ext/plate_1.png', reversed: false},
    {src: '/assets/img/ext/plate_2.png', reversed: true},
    {src: '/assets/img/ext/plate_3.png', reversed: false},
];

export const BMI = [
    [0, 18.4, 'Недостаточный', '#8ECAF9', '#54a8ea'],
    [18.5, 24.9, 'Нормальный', '#62C9C1', '#33b3a8'],
    [25, 34.9, 'Избыточный', '#FEE9A6', '#f8cd3e'],
    [35, 999, 'Ожирение', '#FDB8BF', '#f45f6e'],
];


export const FAQ_ITEMS = [
    {
        title: '🤔 Когда я получу план?',
        text: 'Обычно на подготовку плана уходит несколько часов. Иногда это может занять до 24 часов. Но не волнуйтесь, наша онлайн-поддержка позаботится о вас, если вы не получите план в течение 24 часов.',
    },
    {
        title: '✉️ Что делать, если я не получу письмо?',
        text: 'Больше всего мы ценим наших клиентов и поможем решить любые проблемы. Сначала проверьте папку со спамом, если вы не получили письмо. Хотя вы всегда можете получить доступ к своему плану с сайта, наша служба поддержки будет рада повторно отправить вам электронное письмо, если это необходимо.',
    },
    {
        title: '🗝 Как я могу получить доступ к своему плану питания?',
        text: 'Сразу после оплаты мы сгенерируем план питания на основе ваших ответов на вопросы викторины и ваших пожеланий. Перейдите в меню в правом верхнем углу, выберите «Мой план питания» и введите свой адрес электронной почты. Вы мгновенно получите доступ к своему плану питания.',
    },
];
